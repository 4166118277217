import React from 'react'
import PropTypes from 'prop-types'

import Container from '@objects/container'
import Stage from '@components/stage'
import useStaticModule from '@hooks/useStaticModule'
import Copy from '@objects/copy'

function Barrierfreiheit({ pageContext }) {
  const { getStaticFieldValue } = useStaticModule(pageContext.modules)
  const copy = getStaticFieldValue(
    'accessiblity.copy',
    'accessiblity.copy.text'
  )

  return (
    <>
      <Stage alignTop={true} type={'generic'} title={pageContext.page.title} ariaLabel={pageContext.page.title} />

      <Container width="sm" ariaLabel="arialabel.barrierefreiheit">
        <Copy type="article" html={copy} component="div" />
      </Container>
    </>
  )
}

Barrierfreiheit.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
  modules: PropTypes.array,
}

export default Barrierfreiheit
